import { useContext } from 'react';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Button } from '@forma/forma-ui-kit';
import { queries } from '@/helpers/api';
import Link from '@/components/Link';
import { MainContext } from '@/context/MainContext';

import styles from './Error.module.css';

export async function getServerSideProps({ params }) {
  const mainData = await queries.getMainData(params.locale);

  return {
    props: {
      initialState: {
        main: mainData,
        pageError: {
          errorCode: params.errorCode,
          errorMessage: params.errorCode === 404 ? 'page_not_found' : 'server_error',
          errorDescription: params.errorCode === 404 && 'page_not_found_description'
        }
      },
      ...(await serverSideTranslations(params.locale, ['common'])),
    },
  };
};

const Custom404 = () => {
  const { t, i18n } = useTranslation();
  const { pageError } = useContext(MainContext);
  const title = `${t('main_company_name')} – ${pageError?.errorCode}`;

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: t(pageError?.errorMessage) }} />
          {pageError?.errorDescription && (
            <p className={styles.description}>{t(pageError.errorDescription)}</p>
          )}
          <div className={styles.buttons}>
            <Button
              as={Link}
              viewStyle="primary"
              href="/"
            >
              {t('go_to_homepage')}
            </Button>
          </div>
        </div>
        <img className={styles.image} src="/images/robot_404.svg" width={660} height={547} alt="404" />
      </div>
    </div>
  );
};

export default Custom404;