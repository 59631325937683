import Page, { getServerSideProps as getServerSidePropsPage } from './[locale]/error';
// import { Redirect } from '@/helpers/languageRedirect';
import i18nextConfig from '@/next-i18next.config';

export const getServerSideProps = async ({ res, err }) => {
  const path = res.req.url.split('/');
  const locale = (path[1] && i18nextConfig.i18n.locales.includes(path[1])) ? path[1] : i18nextConfig.i18n.defaultLocale;
  return getServerSidePropsPage({ params: { locale: locale, errorCode: res ? res.statusCode : err ? err.statusCode : 404 } });
};

const Error = (props) => {
  return (
    // <Redirect>
    <Page {...props} />
    // </Redirect>
  );
};

export default Error;